<template>
  <div class="footer">Copyright ©2021 公司 版权所有 技术咨询：010-88888888</div>
</template>
<script>
export default {
  name: "Footer",
  setup() {},
};
</script>
<style lang="scss" scoped>
.footer {
  text-align: center;
  font-size: 15px;
  //font-weight: bold;
  color: rgb(16, 16, 17);
}
</style>